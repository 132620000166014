import { useState } from 'react'

import { ActionIcon, Box, Collapse, Loader } from '@mantine/core'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import IconChevronDown from 'src/icons/IconChevronDown'
import { cn } from 'src/utils'

const AnalyticsCard = ({
  title,
  description,
  badgeText = 'No data',
  loading = false,
  showBadge = false,
  headerChildren = null,
  children,
}) => {
  const [opened, setOpened] = useState(true)

  return (
    <div
      className={
        'flex flex-col gap-2 overflow-hidden rounded border border-doubleNickel-gray-200 p-3 shadow-sm'
      }
    >
      <Box
        className="flex w-full flex-row items-start gap-2 px-2 font-semibold text-doubleNickel-gray-700"
        onClick={() => setOpened((prev) => !prev)}
      >
        <ActionIcon variant="subtle">
          <IconChevronDown
            className={cn(
              'h-4 w-4 fill-none stroke-doubleNickel-gray-500 transition-all',
              !opened && '-rotate-90'
            )}
          />
        </ActionIcon>
        <div className="flex flex-col">
          <div className="flex flex-row gap-4">
            {title}
            {showBadge && (
              <StyledBadge variant="light" className="rounded" color="blue">
                {badgeText}
              </StyledBadge>
            )}
          </div>
          <div className="text-sm font-normal text-doubleNickel-gray-600">
            {description}
          </div>
        </div>
        <div className="ml-auto">{headerChildren}</div>
      </Box>

      <Collapse in={opened} transitionDuration={400}>
        <div className="flex flex-1 py-3">
          {loading ? (
            <Loader
              className="flex flex-1 items-center justify-center"
              size={'xs'}
              color="gray"
            />
          ) : (
            <div className="flex flex-1 flex-col gap-4 overflow-auto">
              {children}
            </div>
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default AnalyticsCard
